import store from "@/store/index";

export default (fields, tableData, name) => {
  store.dispatch("saveExcelButtonStatus", { value: true });

  const header = fields.map((item) => {
    return item.label;
  });

  const data = tableData.map((item) => {
    const row = [];
    for (let prop in item) {
      if (prop !== "_classes") row.push(item[prop]);
    }
    return row;
  });

  import("@/helpers/Export2Excel").then((excel) => {
    excel.export_json_to_excel({
      header: header,
      data: data,
      filename: name,
      autoWidth: true,
      bookType: "xlsx",
    });
    store.dispatch("saveExcelButtonStatus", { value: false });
  });
};
